<template>
  <label :for="id" class="flex items-center cursor-pointer">
    <div class="relative">
      <input type="checkbox" :id="id" class="sr-only peer" :checked="value" @change="toggle" :disabled="disabled" />
      <!-- Toggle -->
      <div :class="[toggleClasses]"></div>
      <div :class="[dotClasses]"></div>
    </div>
    <div class="ml-3 text-gray-700 font-medium text-base">
      {{ label }}
    </div>
  </label>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    label: {
      type: String,
      default: 'Label'
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md',
      validator: (size) => ['sm', 'md', 'lg'].includes(size)
    },
    variant: {
      type: String,
      default: 'default',
      validator: (variant) => ['secondary', 'success', 'danger', 'info', 'default'].includes(variant)
    }
  },
  data() {
    return {
      id: '',
      toggleClasses: [],
      dotClasses: []
    };
  },
  computed: {
    setDotSize() {
      switch (this.size) {
        case 'sm':
          return 'w-2 h-2';
        case 'md':
          return 'w-3 h-3';
        case 'lg':
          return 'w-5 h-5';
        default:
          return 'w-3 h-3';
      }
    },
    setToggleSize() {
      switch (this.size) {
        case 'sm':
          return 'w-6 h-4';
        case 'md':
          return 'w-8 h-5';
        case 'lg':
          return 'w-12 h-7';
        default:
          return 'w-8 h-5';
      }
    },
    setToggleColor() {
      switch (this.variant) {
        case 'secondary':
          return 'bg-blue-500';
        case 'success':
          return 'bg-green-500';
        case 'danger':
          return 'bg-red-500';
        case 'info':
          return 'bg-yellow-500';
        default:
          return 'bg-gray-500';
      }
    },
    setRingColor() {
      switch (this.variant) {
        case 'secondary':
          return 'ring-blue-500';
        case 'success':
          return 'ring-green-500';
        case 'danger':
          return 'ring-red-500';
        case 'info':
          return 'ring-yellow-500';
        default:
          return 'ring-gray-500';
      }
    },
    setFontSize() {
      switch (this.size) {
        case 'sm':
          return 'text-sm';
        case 'md':
          return 'text-base';
        case 'lg':
          return 'text-lg';
        default:
          return 'text-sm';
      }
    }
  },
  mounted() {
    this.id = this._uid.toString();
    this.updateClasses();
  },
  watch: {
    value() {
      this.updateClasses();
    }
  },
  methods: {
    updateClasses() {
      this.toggleClasses = [
        'block',
        'bg-gray-300',
        'rounded-full',
        this.setToggleSize,
        this.value ? this.setToggleColor : '',
        this.value ? 'ring-2 ring-inset' : '',
        this.value ? this.setRingColor : ''
      ];

      this.dotClasses = [
        'dot',
        'absolute',
        'left-1',
        'top-1',
        'bg-white',
        'rounded-full',
        'transition',
        this.setDotSize,
        this.value ? 'transform translate-x-full' : ''
      ];
    },
    toggle() {
      if (!this.disabled) {
        this.$emit('change', !this.value);
      }
    }
  }
};
</script>

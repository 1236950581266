import Axios from '@/axios';

export default {
  listMasterEvent: async (params = {}) => {
    const response = await Axios.get(`/goldrush-service/v2/master-events`, {
      params
    });
    return response.data.data;
  },
  updateMasterEvent: async (id, body) => {
    const response = await Axios.patch(`/goldrush-service/v2/master-events/${id}`, body);

    return response;
  },
  readMasterEvent: async (id) => {
    const response = await Axios.get(`/goldrush-service/v2/master-events/${id}`);

    return response.data.data;
  },
  updateEvents: async (body) => {
    const response = await Axios.patch('/goldrush-service/v2/events', body);

    return response;
  },
  listEvents: async (masterEventId, params = {}) => {
    const response = await Axios.get(`/goldrush-service/master-event/${masterEventId}/events`, {
      params
    });

    return response.data.data;
  }
};

<template>
  <b-col cols="2" class="activate-container">
    <b-button block v-b-modal.activate-gr-modal variant="outline-secondary">Activate Gold Rush Raffle</b-button>

    <b-modal
      id="activate-gr-modal"
      title="Activate Gold Rush Event Series"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
    >
      <form @submit.stop.prevent="onSubmit">
        <div v-if="activateMasterEventFailed" class="alert alert-danger">
          Failed to activate event series: {{ errorMessage }}
        </div>

        <b-form-row>
          <b-form-group
            label="Tickets per event"
            label-for="input-num-tickets"
            class="col"
            :invalid-feedback="veeErrors.first('input-num-tickets')"
          >
            <b-form-spinbutton
              name="input-num-tickets"
              v-model="numTickets"
              v-validate="{
                required: true,
                numeric: true,
                min_value: 1
              }"
              min="0"
              max="100000000"
              step="100"
              :state="validateState('input-num-tickets')"
              aria-describedby="input-num-tickets-feedback"
              data-vv-as="tickets per event"
            />
          </b-form-group>

          <b-form-group label="Barrel draw" label-for="input-barrel-draw" class="col">
            <b-form-select
              name="input-barrel-draw"
              v-model="barrelDraw"
              aria-describedby="input-barrel-draw-feedback"
              :options="options"
            />
          </b-form-group>
        </b-form-row>

        <b-form-group
          label="Event logo"
          label-for="input-logo"
          :invalid-feedback="veeErrors.first('input-logo')"
          description="URL for the logo to be displayed on POS devices."
        >
          <b-form-input
            name="input-logo"
            v-model="logo"
            v-validate="{ url: { require_protocol: true } }"
            :state="validateState('input-logo')"
            aria-describedby="input-logo-feedback"
            data-vv-as="logo"
            placeholder="eg. https://rafflebox.us/images/rafflebox-header.png"
          />
        </b-form-group>

        <b-form-group
          label="Ticket pattern"
          label-for="input-ticket-pattern"
          description="A pattern for your ticket numbers. Use the hash symbol (#) to indicate where the ticket number should be placed."
          :invalid-feedback="veeErrors.first('input-ticket-pattern')"
        >
          <b-form-input
            name="input-ticket-pattern"
            v-model="ticketPattern"
            v-validate="{ required: true, regex: /.?#.?/ }"
            :state="validateState('input-ticket-pattern')"
            aria-describedby="input-ticket-pattern-feedback"
            placeholder="eg. RB-#"
            data-vv-as="ticket pattern"
          />
        </b-form-group>

        <b-form-group
          label="Starting pot"
          label-for="input-starting-pot"
          :invalid-feedback="veeErrors.first('input-starting-pot')"
        >
          <b-form-input
            name="input-starting-pot"
            v-model="startingPot"
            v-validate="{ required: true, decimal: 2 }"
            :state="validateState('input-starting-pot')"
            aria-describedby="input-starting-pot-feedback"
            data-vv-as="starting pot"
          />
        </b-form-group>

        <b-form-group
          label="Ticket header"
          label-for="input-ticket-header"
          :invalid-feedback="veeErrors.first('input-ticket-header')"
          description="Header text to be printed on tickets."
        >
          <b-form-input
            name="input-ticket-header"
            v-model="ticketHeader"
            v-validate="{ required: true }"
            :state="validateState('input-ticket-header')"
            aria-describedby="input-ticket-header-feedback"
            data-vv-as="ticket header"
          />
        </b-form-group>

        <b-form-group
          label="Ticket footer"
          label-for="input-ticket-footer"
          :invalid-feedback="veeErrors.first('input-ticket-footer')"
          description="Footer text to be printed on tickets."
        >
          <b-form-input
            name="input-ticket-footer"
            v-model="ticketFooter"
            v-validate="{ required: true }"
            :state="validateState('input-ticket-footer')"
            aria-describedby="input-ticket-footer-feedback"
            data-vv-as="ticket footer"
          />
        </b-form-group>

        <b-form-group
          label="Shortlink"
          label-for="input-shortlink"
          :invalid-feedback="veeErrors.first('input-shortlink')"
          description="Appended to rafflebox.us/<shortlink> for your customers to view."
        >
          <b-form-input
            name="input-shortlink"
            v-model="shortlink"
            v-validate="{ required: true, regex: /^[a-zA-Z0-9-]+$/ }"
            :state="validateState('input-shortlink')"
            aria-describedby="input-shortlink-feedback"
            data-vv-as="shortlink"
          />
        </b-form-group>

        <b-form-group
          label="Blurb"
          label-for="input-blurb"
          :invalid-feedback="veeErrors.first('input-blurb')"
          description="The content to display on your POS devices. Supports HTML and Markdown."
        >
          <!-- hidden textarea for validation -->
          <b-form-textarea
            hidden
            name="input-blurb"
            v-model="blurb"
            v-validate="{ required: true }"
            :state="validateState('input-blurb')"
            aria-describedby="input-blurb-feedback"
            data-vv-as="blurb"
          />

          <markdown-editor
            data-vv-name="input-blurb"
            v-model="blurb"
            toolbar="bold italic strikethrough heading | picture | numlist bullist | preview"
            @command:picture="addImageToBlurb"
            :extend="customMarkdown"
            theme="secondary-outline"
          />
        </b-form-group>
      </form>
    </b-modal>
  </b-col>
</template>

<script>
import axios from '@/axios';
import showdown from 'showdown';

export default {
  data: function () {
    return {
      numTickets: 100,
      barrelDraw: false,
      ticketPattern: null,
      startingPot: 0,
      ticketHeader: null,
      ticketFooter: null,
      shortlink: null,
      logo: null,
      blurb: '',
      options: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      blurbOptions: {},
      customMarkdown: {
        picture: {
          cmd: 'picture',
          ico: 'fas fa-image',
          title: 'Image'
        }
      },
      activateMasterEventFailed: false,
      errorMessage: null
    };
  },

  props: {
    id: { type: String, default: '' },
    masterTicketFooter: { type: String, default: '' }
  },

  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      const converter = new showdown.Converter();
      const blurbHtml = converter.makeHtml(this.blurb);

      try {
        await axios.post(`/goldrush-service/master-event/${this.id}/activate`, {
          numTickets: this.numTickets,
          barrelDraw: this.barrelDraw,
          ticketPattern: this.ticketPattern,
          startingPot: this.startingPot,
          ticketHeader: this.ticketHeader,
          ticketFooter: this.masterTicketFooter || this.ticketFooter,
          blurb: blurbHtml,
          shortlink: this.shortlink,
          logo: this.logo
        });
        // refresh page to switch states
        this.$router.go();
      } catch (error) {
        this.activateMasterEventFailed = error;

        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },

    resetForm() {
      this.numTickets = 100;
      this.barrelDraw = false;
      this.ticketPattern = null;
      this.startingPot = 0;
      this.ticketHeader = null;
      this.ticketFooter = null;
      this.shortlink = null;
      this.blurb = '';

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    addImageToBlurb(md) {
      md.drawImage({ url: 'https://your-image-url-here', title: '' });
    }
  }
};
</script>

<style scoped>
.tickets-for {
  text-align: center;
  margin-top: 0.5rem;
}
.activate-container {
  margin-bottom: 1rem;
}
</style>

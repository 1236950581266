import axios from '@/axios';
import S3Uploader from 'axios'; // Separate axios instance to bypass our incerceptors/baseURL

export default {
  uploadFile: async (formData) => {
    const response = await axios.post('/file-upload-service/v2/upload/images', formData);

    return response.data.data.imageUrl;
  },
  getUploadUrl: async (formData, body) => {
    const response = await axios.post('/file-upload-service/v2/tempfile', formData, body);

    return response.data.data.url;
  },
  uploadToS3: async (url, file) => {
    const response = await S3Uploader.put(url, file, {
      headers: {
        'Content-Type': 'text/csv',
        'x-amz-server-side-encryption': 'AES256'
      }
    });
    return response.data;
  }
};
